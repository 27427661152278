var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
Drupal.behaviors.utilityNavV1 = (function ($, _, site, generic) {
  'use strict';

  // Private variables:
  var $blocks = $();
  var $trigger = $();
  var $counters = $();
  var data = {
    item_count: 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    singular: 0,
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var closeNav = false;
  var closeTimeout;

  // Replace dots in the top-level key names the server is giving us.
  // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
  function _normalizeResponseKeys(items) {
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    };
    var normalizeArray = function (array) {
      var newArray = [];

      for (var x = 0, alen = array.length; x < alen; x++) {
        var innerObj = {};

        if (typeof array[x] === 'string') {
          // When the array just has a string, just push that.
          newArray.push(array[x]);
        } else {
          // When the array is anything else, just push that.
          for (var innerKey in array[x]) {
            innerObj[replaceKey(innerKey)] = array[x][innerKey];
          }
          newArray.push(innerObj);
        }
      }

      return newArray;
    };
    var out = [];

    for (var i = 0, len = items.length; i < len; i++) {
      out[i] = {};
      for (var key in items[i]) {
        if (items[i].hasOwnProperty(key)) {
          // Need to go deeper for collection items.
          if ($.isArray(items[i][key])) {
            out[i][replaceKey(key)] = normalizeArray(items[i][key]);
          } else {
            out[i][replaceKey(key)] = items[i][key];
          }
        }
      }
    }

    return out;
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }
    closeTimeout = setTimeout(function () {
      if (closeNav) {
        // Drupal.behaviors.gnav.close();
        $(document).trigger('gnav.close');
      }
      behavior.setState();
    }, 5000);
  }

  function _clearCloseTimeout() {
    clearTimeout(closeTimeout);
  }

  // Public methods:
  var behavior = {
    attach: function (context) {
      $trigger = $trigger.add($('.utility-nav--cart-item', context));
      $blocks = $blocks.add($('.js-cart-block-container', context));
      $counters = $counters.add($('.utility-nav__cart-count', context));
      var isLoyalty = +site.userInfoCookie.getValue('is_loyalty_member');
      var firstName = site.userInfoCookie.getValue('first_name');
      var currentPoint = site.userInfoCookie.getValue('current_available');
      var enableLoyaltyGnav = $('.utility-nav__loyalty-text', context).attr('data-loyalty-gnav');

      if (enableLoyaltyGnav && isLoyalty) {
        $('.utility-nav__wrapper', context).addClass('hidden');
        $('.utility-nav__loyalty-text', context).removeClass('hidden');
        $('.utility-nav__loyalty-text span:nth-child(1)', context).text(firstName);
        $('.utility-nav__loyalty-text span:nth-child(2)', context).text(currentPoint);
      }
      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      if (site.userInfoCookie) {
        this.setData({ item_count: site.userInfoCookie.getValue('item_count') - 0 });
      }
    },

    render: function () {
      // Bundle Item discount message
      if (data && data.items) {
        $.each(data.items, function (i, items) {
          if (data.items[i].hasOwnProperty('isBundleItem') && data.items[i].isBundleItem) {
            data.display_bundle_info = 1;
          }
        });
      }

      var rendered = site.template.get({
        name: 'gnav_cart_content',
        data: data
      });

      // Some of the field values may actually contain mustache themselves, so
      // this template needs to be run through rendering a second time.
      rendered = site.template.render(rendered, data);
      $blocks.each(function () {
        $(this).html(rendered);
      });
      // Update the counters that are outside of the template
      $counters.each(function () {
        $(this).text(data.item_count);
      });

      return this;
    },

    load: function () {
      $blocks.each(function () {
        $(this).addClass('loading');
      });
      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $blocks.each(function () {
            $(this).removeClass('loading');
          });
          var value = response.getValue();

          if (_.isUndefined(value) || !value) {
            return;
          }

          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(value.order.items)
          });
        },
        onError: function () {
          $blocks.each(function () {
            $(this).removeClass('loading');
          });
          // @TODO: a failure message should go here.
          loaded = false;
        }
      });
      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      if (site && site.track) {
        site.track.cartOverlay();
      }

      return this;
    },

    addItem: function (result) {
      if (_.isUndefined(result) || !result || _.isUndefined(result.trans_data) || _.isUndefined(result.ac_results)) {
        return this;
      }
      var resultType = this.getResultType(result.ac_results);
      var addedItems = '';
      var $tr = $trigger.filter(':visible').length > 1 ? $trigger.first() : $trigger.filter(':visible');

      if (resultType !== 'sku') {
        addedItems = this.setCollection(result);
      } else {
        var allResults = result;

        addedItems = _.map(result.ac_results, function (value) {
          var res = value.result;
          var item = res.CARTITEM;

          // Seems very dumb to calculate this on the front end.
          item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);
          var skuBaseID = item['sku.SKU_BASE_ID'];

          if (skuBaseID != null) {
            if (
              allResults &&
              allResults.trans_data &&
              allResults.trans_data.order &&
              allResults.trans_data.order.items
            ) {
              var allItems = allResults.trans_data.order.items;

              $.each(allItems, function (i, items) {
                if (items['sku.SKU_BASE_ID'] === skuBaseID) {
                  item.preOrderMsgShort = items.preOrderMsgShort;
                }
              });
            }
          }

          return item;
        });
      }

      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points,
        items: _normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');
      // Drupal.behaviors.gnav.open($trigger);
      $(document).trigger('gnav.open', [$tr]);
      if (site && site.track) {
        site.track.cartOverlay();
      }
      closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
      } else if (!_.includes(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }
      $blocks.each(function () {
        $(this).removeClass(stateClasses).addClass(classPrefix + state);
      });
      $trigger.each(function () {
        $(this).toggleClass('gnav-util--cart--nonempty', state !== 'empty');
      });

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      data.singular = data.item_count === 1;
      this.setState().render();

      return this;
    },

    setCollection: function (results) {
      if (!results || !results.ac_results || !results.trans_data) {
        return null;
      }
      var transData = results.trans_data;
      var orderData = transData.order;
      var collectionIds = _.map(results.ac_results, function (obj) {
        var result = obj.result;

        return result && result.COLLECTION_ID && result.CARTITEM ? result.COLLECTION_ID : null;
      });
      var collectionId = _.compact(collectionIds);
      var collectionResult = _.filter(orderData.items, function (item) {
        return _.includes(collectionId, item.COLLECTION_ID);
      });

      return collectionResult;
    },

    // Getters:
    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      var type = 'sku';
      var isCollection = _.filter(results, function (result) {
        return result.instance === 'alter_collection';
      });

      if (isCollection.length > 0) {
        type = 'collection';
      }
      var isReplenishment = _.filter(results, function (result) {
        return result.instance === 'alter_replenishment' && result.type === 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    }
  };

  // Document listeners:
  $(document).on('addToCart.success', function (event, result) {
    behavior.addItem(result);
  });

  // Override preventDefault on gnav overlay logic if cart is empty:
  $(document).on('click', '.js-gnav-cart-trigger', function () {
    if (state === 'empty') {
      // Drupal.behaviors.gnav.close();
      $(document).trigger('gnav.close');
      window.location = $(this).attr('href');

      return true;
    }
  });

  $('.js-cart-block-container').on('mouseenter', _clearCloseTimeout);
  $('.js-cart-block-container').on('mouseleave', _setCloseTimeout);

  $(document).on('click', '.cart-block__items__view-bag', function (event) {
    event.preventDefault();
    _clearCloseTimeout();
    behavior.setState().render();
  });

  $(document).on('gnav-util.open', function (event, args) {
    if (args.$el.hasClass('utility-nav--cart-item')) {
      // Do nothing if the cart is empty:
      if (state === 'empty' || state === 'added') {
        return;
      }
      behavior.load();
    }
  });

  $(document).on('gnav-util.cart.update', function () {
    behavior.load();
  });

  $(document).on('click keydown', '.js-cart-close', function (e) {
    var keycode = site.getKeycode(e);

    if (keycode === 1 || keycode === 13) {
      e.preventDefault();
      // Drupal.behaviors.gnav.close();
      $(document).trigger('gnav.close');
      closeNav = false;
    }
  });

  $(document).on('focusout', '.js-gnav-cart-checkout', function () {
    $('.js-cart-close span').focus();
  });

  // Cancel the auto-close timer when the utility nav closes.
  $(document).on('gnav-util.close', function () {
    closeNav = false;
  });

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
